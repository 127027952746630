import { captureException } from '@sentry/nextjs';
import api from 'src/utils/api';
import { generateAvatarLinkFromHash, generateBannerLinkFromHash } from 'src/utils/linkUtils';

export const validateUser = async (username: string): Promise<boolean> => {
  try {
    const { data } = await api.getPublic(`user/check-username?username=${encodeURIComponent(username)}`);
    return data?.isUsername;
  } catch (ex) {
    captureException(ex);
    return false;
  }
};

export const saveAndCropAvatarImage = async (userId: string, formData: FormData) => {
  const { data: { imageHash } } = await api.post('private/images/avatars', formData);

  // updates user avatar with new image
  await api.put(`private/users/${userId}/profile/basics`, {
    'basic-data': { userImageHash: imageHash },
  });

  const imageUrl = generateAvatarLinkFromHash(imageHash);
  return { imageUrl };
};

export const saveAndCropBannerImage = async (userId: string, formData: FormData) => {
  const { data: { imageHash } } = await api.post('private/images/user-banners', formData);

  // updates user banner with new image
  await api.put(`private/users/${userId}/profile/basics`, {
    'basic-data': { userBannerHash: imageHash },
  });

  const imageUrl = generateBannerLinkFromHash(imageHash);
  return { imageUrl };
};
