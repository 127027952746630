// @ts-strict-ignore
import { Fragment } from 'react';
import styled from 'styled-components';
import i18n from 'src/utils/translate';
import { isCategoryAccepted, Category } from 'src/utils/cookie';
import Button from 'src/components/buttons/button';
import YouTubeEmbed from 'src/components/youTubeEmbed';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import { LinkButton } from 'styles-js/buttons';
import { textSmall } from 'styles-js/mixins/typography';

const isServer = typeof window === 'undefined';

// Return an inline links to the an URL
export function getLink(
  { url, originalText, text, isWhiteList, youtubeID: youTubeId },
  editable = false,
  popOutEnabled = false,
): JSX.Element {

  if (editable) {
    return <Fragment key={originalText}>originalText</Fragment>;
  }

  // We display a YouTube player only if the user has accepted functionality cookies
  if (youTubeId && !isServer && isCategoryAccepted(Category.Functionality)) {
    const formattedYouTubeId = formatYouTubeId(youTubeId);

    return (
      <Fragment key={youTubeId}>
        {popOutEnabled && <VideoPopup formattedYouTubeId={formattedYouTubeId} />}
        <YouTubeEmbed id={formattedYouTubeId} title="HealthUnlocked Video" />
        <LinkButton onClick={() => helpScoutArticle('109-why-my-youtube-video-is-not-playing')}>
          {i18n.t('Not working as expected?')}
        </LinkButton>
        <br />
      </Fragment>
    );
  }

  return (
    <Fragment key={text}>
      <a
        href={url}
        rel={isWhiteList ? undefined : 'nofollow ugc noreferrer'}
        target="_blank"
      >
        {text}
      </a>
      {// When the user has rejected functionality cookies
        youTubeId && (
          <StyledLinkButton onClick={() => helpScoutArticle('109-why-my-youtube-video-is-not-playing')}>
            ({i18n.t('Not working as expected?')})
          </StyledLinkButton>
        )}
    </Fragment>
  );
}

function VideoPopup({ formattedYouTubeId }: { formattedYouTubeId: string }) {
  const formattedYouTubeLink = `//www.youtube.com/embed/${formattedYouTubeId}`;

  const stopVideo = (element: Document) => {
    const iframe = element.querySelector('iframe');
    if (iframe !== null) {
      const iframeSrc = iframe.src;
      iframe.src = iframeSrc;
    }
  };
  return (
    <VideoPopupWrapper>
      <Button
        onClick={() =>{
          stopVideo(document);
          window.open(formattedYouTubeLink, 'video', 'height=270,width=480');
        }}
      >
        {i18n.t('Pop out video')}
      </Button>
      <VideoPopupDescription>
        {i18n.t('Click on "Pop out video" to watch the video in a separate window to be able to refresh the current page with comments independently.')}
      </VideoPopupDescription>
    </VideoPopupWrapper>
  );
}

// Format the YouTube ID to a valid '/embed/' format e.g. bMYCn-wR80?start=144
const formatYouTubeId = (youTubeId: string): string => {
  if (youTubeId.indexOf('?') === -1) return youTubeId;
  const params = youTubeId.substring(youTubeId.indexOf('?') + 1);
  return youTubeId.replace(params, params.replace('s', '').replace('t=', 'start='));
};

const StyledLinkButton = styled(LinkButton)`
  margin-left: 3px;
`;

const VideoPopupWrapper = styled.div`
  border-top: ${({ theme }) => theme.greyLine};
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  @media (${({ theme }) => theme.underScreenMedium}) {
    flex-direction: column-reverse;
    align-items: stretch;
  }
`;
const VideoPopupDescription = styled.div`
  ${textSmall}
  color: ${({ theme }) => theme.colorTextLight};
`;
