import { generatePromoImageLink, prepLink } from 'src/utils/linkUtils';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import Card from 'src/components/card';

type Props = {
  widget: {
    imageHash: string;
    url: string;
  }
}
export default function CommunityHomePromoImage({ widget }: Props): JSX.Element {
  const imageUrl = generatePromoImageLink(widget.imageHash);
  const linkUrl = prepLink(widget.url);
  return (
    <Card>
      <a
        aria-label="community promotion"
        className="campaign-image"
        href={linkUrl}
        rel="noreferrer"
        style={{ backgroundImage: `url("${imageUrl}")` }}
        target="_blank"
        title="community promotion"
        onClick={() => {
          trackFootfallEvent(Event.Clicked, {
            clickType: 'community-promo-image',
            clickMetadata: { section: Section.CommunitySideBar },
          });
        }}
      />
    </Card>
  );
}
