import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'src/store/store';
import { ModalType } from 'src/constants/modalTypes';
import { showModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import i18n from 'src/utils/translate';
import { LinkButton } from 'styles-js/buttons';
import { Title } from 'src/modules/community/shared/components/featuredPosts/normalFeaturedPost';
import { dismissFeaturedPost } from 'src/modules/shared/context/actions';

export default function DismissFeedback({ featuredPostId }: { featuredPostId: number }) {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(dismissFeaturedPost());
  };

  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    trackFootfallEvent(Event.Clicked, { clickType: 'campaign-Feedback', clickMetadata: e.target.value });
    if (e.target.value === 'other') {
      dispatch(showModal({ modalType: ModalType.Feedback, modalProps: {
        campaignId: featuredPostId,
        title: i18n.t('Send us feedback about this campaign'),
        onClose,
      } }));
    } else {
      onClose();
    }
  };

  return (
    <div className="results-post">
      <PreTitle>
        {i18n.t('Improve your experience')}
        <LinkButton onClick={onClose}>{i18n.t('Hide')}</LinkButton>
      </PreTitle>
      <Title className="results-post__title">{i18n.t('Why are you hiding this?')}</Title>
      <Label>
        <input type="radio" value="irrelevant" onChange={onSelect} />
        {i18n.t(`It's irrelevant`)}
      </Label>
      <Label>
        <input type="radio" value="too-often" onChange={onSelect} />
        {i18n.t('I see it too often')}
      </Label>
      <Label>
        <input type="radio" value="other" onChange={onSelect} />
        {i18n.t('Other (please explain)')}
      </Label>
    </div>
  );
}

const PreTitle = styled.div`
  display: flex;
  margin-bottom: 10px;
  button {
    margin-left: auto;
  }
`;

const Label = styled.label`
  display: flex;
  input {
    margin-right: 10px;
  }
`;
