// @ts-strict-ignore
import { Fragment, cloneElement, useMemo } from 'react';
import { useSelector } from 'src/store/store';
import { userHasRole } from 'src/utils/context';
import { Role } from 'src/constants/types';
import { stringToKey } from 'src/utils/string';
import { getText } from 'src/utils/getElement/getText';
import { getLink } from 'src/utils/getElement/getLink';
import { getSmiley } from 'src/utils/getElement/getSmiley';
import { getMention } from 'src/utils/getElement/getMention';

export type EnrichedContent = {
  type: 'text' | 'link' | 'mention' | 'smiley' | 'return';
  text: string;
  style: {
    b?: boolean;
    i?: boolean;
    u?: boolean;
  }
}[]

export default function GetElement({ enrichedContent, showVideoPopout = false }: { enrichedContent: EnrichedContent, showVideoPopout?: boolean }): JSX.Element {
  const { currentCommunity, currentUser } = useSelector(({ context }) => context);
  const author = useSelector(({ pages }) => pages.singlePost?.post?.author);
  const isLoggedIn = !!currentUser;
  // pancreatitis-support commId=1001, johns=18 - p/panc-support is production only !
  const popOutEnabled = showVideoPopout && (currentCommunity.communityId === 1001 || currentCommunity.communityId === 18) &&
  userHasRole(author, [Role.HealthUnlocked, Role.Partner, Role.Administrator, Role.Moderator]);
  const element = useMemo(() => getElement(enrichedContent, false, isLoggedIn, popOutEnabled), [enrichedContent, isLoggedIn, popOutEnabled]);
  return (
    <Fragment>
      {element}
    </Fragment>
  );
}

export const getElement = (enrichedText = [], editable = false, isLoggedIn = true, popOutEnabled = false) => {
  if (!enrichedText || enrichedText.length === 0) {
    return null;
  }
  const result = enrichedText
    .reduce(([lastParagraph, ...paragraphs], item) => {
      switch (item.type) {
        case 'mention':
          return [[...lastParagraph, getMention(item, editable, isLoggedIn)], ...paragraphs];
        case 'smiley':
          return [[...lastParagraph, getSmiley(item, editable)], ...paragraphs];
        case 'link': {
          const link = getLink(item, editable, popOutEnabled);
          return link.type === 'div' ?
            [[], link, lastParagraph, ...paragraphs] :
            [[...lastParagraph, link], ...paragraphs];
        }
        case 'return':
          return [[], lastParagraph, ...paragraphs];
        case 'text':
          return [[...lastParagraph, getText(item, editable)], ...paragraphs];
        default:
          return [lastParagraph, ...paragraphs];
      }
    }, [[]]).reverse()
    .map((paragraph) => paragraph.type === 'div'
      ? cloneElement(paragraph, { key: stringToKey(paragraph) })
      : <p key={stringToKey(paragraph)}>{paragraph}</p>,
    );
  return result;
};
