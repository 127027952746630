// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import actionTypes from 'src/modules/community/communityHome/actionTypes';
import { createAuthenticatedApiInstance } from 'src/utils/api';
import { setAlert } from 'src/components/alert/actions';

export const fetchPosts = ({
  communitySlug,
  filter = 'latest',
  enableFallBack = false,
}: {
  communitySlug: string;
  filter?: string;
  enableFallBack?: boolean;
}) => {
  const POST_FILTERS = ['latest', 'popular'];
  const postsFilter = POST_FILTERS.includes(filter)
    ? filter
    : 'latest';

  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_COMMUNITY_POSTS, filter: postsFilter });
    try {
      const api = createAuthenticatedApiInstance(getState);
      const state = getState();
      const url = `posts/${communitySlug}/${postsFilter}`;

      const { data: posts } = !state.context.currentUser?.isCommunityMemberOrHUStaff
        ? await api.getPublic(url)
        : await api.get(`private/${url}`);

      // If we don't have 5 popular posts, we change filter to 'latest'
      if (enableFallBack && filter === 'popular' && posts.length < 5) {
        return dispatch(fetchPosts({ communitySlug, filter: 'latest' }));
      }

      dispatch({
        type: actionTypes.FETCH_COMMUNITY_POSTS_SUCCESS,
        posts,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_COMMUNITY_POSTS_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export function fetchPostsWithFilter({ communitySlug, isLoggedIn }) {
  return fetchPosts({ communitySlug, filter: isLoggedIn ? 'latest' : 'popular', enableFallBack: !isLoggedIn });
}

export const fetchWidgets = (communitySlug: string) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_COMMUNITY_WIDGETS });
    try {
      const api = createAuthenticatedApiInstance(getState);
      const state = getState();
      const url = `communities/${communitySlug}/widgets`;

      const { data } = !state.context.currentUser?.isCommunityMemberOrHUStaff
        ? await api.getPublic(url)
        : await api.get(`private/${url}`);

      dispatch({
        type: actionTypes.FETCH_COMMUNITY_WIDGETS_SUCCESS,
        widgets: data,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_COMMUNITY_WIDGETS_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};
