import styled from 'styled-components';
import { titleMedium, textSmall, textWideSpacing } from 'styles-js/mixins/typography';

export const PostItem = styled.div`
  padding: 25px 0;
  border-bottom: ${({ theme }) => theme.greyLine};

  &:last-of-type {
    border-bottom: none;
  }

  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 25px 20px; // 20px left/right - on mobile, border is reaching screen-edge to screen-edge.
  }

  a:focus, a:hover, h3:focus, h3:hover {
    color: ${({ theme }) => theme.colorBlackHover};
  }
`;

export const Title = styled.h3`
  ${titleMedium};
  margin: 0 0 5px;
  overflow-wrap: break-word;
`;

export const MetaWrapper = styled.div`
  display: flex;
  align-items: center;

  a {
    color: ${({ theme }) => theme.colorTextBlack};
    font-weight: ${({ theme }) => theme.fontWeightBold};
  }
`;

export const AvatarLink = styled.a`
  margin-top: 5px;
`;

export const MetaTextWrapper = styled.div<{ margin?: string }>`
  margin-left: ${({ margin }) => margin ? margin : '10px'};
  color: ${({ theme }) => theme.colorTextLight};
`;

export const Body = styled.span`
  ${textWideSpacing};
  display: -webkit-box;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const ReplyBody = styled(Body)`
  -webkit-line-clamp: 2;
`;

export const PopularBadge = styled.span`
  ${textSmall};
  margin-left: 5px;
  padding: 0 7px 2px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colorYellow};
`;
