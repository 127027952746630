// @ts-strict-ignore
import Link from 'next/link';
import styled from 'styled-components';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { parseString } from 'src/utils/parserText';
import { prepLink } from 'src/utils/linkUtils';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section, Element } from 'src/constants/footfall';
import SocialButton, { Brand } from 'src/components/buttons/socialButton';
import Card from 'src/components/card';
import Icon from 'src/components/icon';
import GetElement from 'src/components/getElement';

type AboutUsWidget = {
  description?: string;
  website?: string;
  phone?: string;
  facebook?: string;
  twitter?: string;
  instagram?: string;
  youtube?: string;
  name?: string;
  widgetType: 'ContactUs';
}
type Props = {
  widget: AboutUsWidget;
}

export default function CommunityHomeAboutUs({ widget }: Props): JSX.Element {
  const currentCommunity = useSelector(({ context }) => context.currentCommunity);

  const hasSocialButton = widget.facebook || widget.twitter || widget.instagram || widget.youtube;

  if (hasSocialButton && !widget.description && !widget.website && !widget.phone) {
    return null;
  }

  const description = widget.description?.length > 110
    ? widget.description.slice(0, 110) + '...'
    : widget.description;

  return (
    <Card title={i18n.t('About us')}>
      <Div>
        <GetElement enrichedContent={parseString(description, [])} />
        {'  '}
        <Link
          as={`/${currentCommunity.slug}/about`}
          href={`/community/communityAbout?communitySlug=${currentCommunity.slug}`}
          onClick={() => footfall('community-read-more', { section: Section.CommunitySideBar })}
        >
          <span>{i18n.t('Read more about')}{' '}{currentCommunity.name} <Icon icon="open-right" /></span>
        </Link>
      </Div>
      {widget.website && (
        <Div>
          <a
            href={prepLink(widget.website)}
            rel="noreferrer"
            target="_blank"
            onClick={() => footfall('community-website', { section: Section.CommunitySideBar })}
          >
            {widget.website}
          </a>
        </Div>
      )}
      {widget.phone && <Div>{widget.phone}</Div>}
      {hasSocialButton && (
        <Div>
          {widget?.facebook && <SocialLink buttonType="facebook" href={prepLink(widget.facebook)} />}
          {widget?.twitter && <SocialLink buttonType="twitter" href={`https://twitter.com/${widget.twitter}`} />}
          {widget?.instagram && <SocialLink buttonType="instagram" href={`https://instagram.com/${widget.instagram}`} />}
          {widget?.youtube && <SocialLink buttonType="youtube" href={prepLink(widget.youtube)} />}
        </Div>
      )}
    </Card>
  );
}

function footfall(clickType: string, clickMetadata: { section: Section, element?: Element }) {
  trackFootfallEvent(Event.Clicked, { clickType, clickMetadata });
}

function SocialLink({ buttonType, href }: { buttonType: Brand, href: string }) {
  return (
    <a
      href={href}
      rel="noreferrer"
      target="_blank"
      onClick={() => footfall('community-social-icon', { section: Section.CommunitySideBar, element: buttonType as Element })}
    >
      <SocialButton brand={buttonType} />
    </a>
  );
}

const Div = styled.div`
  margin-bottom: 10px;
`;
