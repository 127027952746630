// @ts-strict-ignore
import styled from 'styled-components';
import i18n from 'src/utils/translate';
import { prepLink } from 'src/utils/linkUtils';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import Card from 'src/components/card';
import { SecondaryButton } from 'styles-js/buttons';

type Props = {
  widget: {
    body?: string;
    buttonText?: string;
    url?: string;
    title?: string;
  }
}
export default function CommunityHomeSupportUs({ widget }: Props): JSX.Element {
  if (!widget.body && !widget.buttonText && !widget.url) {
    return null;
  }

  return (
    <Card title={widget.title || i18n.t('Support Us')}>
      <div>{widget.body}</div>
      <div>
        <StyledLink
          as="a"
          href={prepLink(widget.url)}
          rel="noreferrer"
          target="_blank"
          onClick={() => {
            trackFootfallEvent(Event.Clicked, {
              clickType: 'community-support-us',
              clickMetadata: { section: Section.CommunitySideBar },
            });
          }}
        >
          {widget.buttonText}
        </StyledLink>
      </div>
    </Card>
  );
}

const StyledLink = styled(SecondaryButton)`
  margin-top: 10px;
`;
