import { format } from 'date-fns/format';
import { truncate } from 'underscore.string';
import i18n from 'src/utils/translate';
import { getUTCDate } from 'src/utils/date';
import { prepLink } from 'src/utils/linkUtils';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import Card from 'src/components/card';
import Icon from 'src/components/icon';

type Props = {
  events: {
    date: string;
    id: string;
    isDateOnly: boolean;
    name: string;
    url: string;
  }[];
}
export default function CommunityHomeEvents({ events }: Props): JSX.Element {
  return (
    <Card title={i18n.t('Events')}>
      {events.map(communityEvent => {
        const eventDate = getUTCDate(communityEvent.date);
        const eventName = truncate(communityEvent.name, 60);
        return (
          <div className="community__widget__event" key={communityEvent.id} >
            <div className="community__widget__event__calendar">
              <div className="community__widget__event__calendar-page">
                {format(eventDate, 'dd')}<br />{format(eventDate, 'MMM')}
              </div>
            </div>
            <div className="community__widget__event__content">
              <div className="community__widget__event__content-title">
                {communityEvent.url
                  ? (
                    <a
                      href={prepLink(communityEvent.url)}
                      rel="noreferrer"
                      target="_blank"
                      onClick={() => {
                        trackFootfallEvent(Event.Clicked, {
                          clickType: 'community-event',
                          clickMetadata: { section: Section.CommunitySideBar },
                        });
                      }}
                    >
                      {eventName}{' '}
                      {communityEvent.url && <Icon icon="link-ext" />}
                    </a>
                  )
                  : <span>{eventName}</span>}
              </div>
              <div className="community__widget__event__content-subtitle">
                {communityEvent.isDateOnly
                  ? format(eventDate, 'MMMM dd, yyyy')
                  : format(eventDate, 'MMMM dd, yyyy - h:mm a')}
              </div>
            </div>
          </div>
        );
      })}
    </Card>
  );
}
