
import { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Featured } from 'src/constants/types';
import { formatLink } from 'src/utils/featuredPosts';
import i18n from 'src/utils/translate';
import { LinkButton, SecondaryButton } from 'styles-js/buttons';

type Props = {
  featuredPost: Featured;
  onClick: (e: SyntheticEvent) => void;
  onDismiss: () => void;
}

export default function NormalFeaturedPost({ featuredPost, onClick, onDismiss }: Props) {
  const formattedLink = formatLink(featuredPost.callToActionUrl);

  return (
    <div className="results-post">
      {featuredPost.imageUrl && (
        <a
          href={formattedLink}
          rel="nofollow ugc noreferrer"
          target="_blank"
          onClick={onClick}
        >
          <StyledImage alt={featuredPost.title} loading="lazy" src={featuredPost.imageUrl} />
        </a>
      )}
      <Title className="results-post__title">
        <a
          href={formattedLink}
          rel="nofollow ugc noreferrer"
          target="_blank"
          onClick={onClick}
        >
          {featuredPost.title}
        </a>
        <LinkButton onClick={onDismiss}>{i18n.t('Hide')}</LinkButton>
      </Title>
      <div className="results-post__body">
        {featuredPost.snippet}
      </div>
      <div>
        <SecondaryButton
          as="a"
          href={formattedLink}
          rel="nofollow ugc noreferrer"
          target="_blank"
          onClick={onClick}
        >
          <Cta>
            {featuredPost.callToActionText}
          </Cta>
        </SecondaryButton>
      </div>
      <Footer>
        <FooterContent>
          {i18n.t('Featured by')} {featuredPost.featuredByCommunityName}
        </FooterContent>
      </Footer>
    </div>
  );
}

export const Title = styled.h3`
  display: flex;
  a {
    flex: 1;
  }
  button {
    align-self: flex-start;
  }
`;
export const Cta = styled.span`
  padding: 10px;
  color: ${({ theme }) => theme.colorTextLinkPrimary};
  cursor: pointer;

  &:hover, &:active {
    color: ${({ theme }) => theme.colorTextLinkPrimaryHover};
  }
`;
const StyledImage = styled.img`
  width: 100%;
  height: auto;
  padding-bottom: 15px;

  @media (${({ theme }) => theme.underScreenSmall}) {
    padding-bottom: 10px;
  }
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const FooterContent = styled.div`
  color: ${({ theme }) => theme.colorGreyDark};
`;
