// actions
import { slugify } from 'underscore.string';
import { fetchPostsWithFilter, fetchWidgets } from 'src/modules/community/communityHome/actions';
import { fetchCurrentCommunity } from 'src/modules/shared/context/actions';
import { validateTag } from 'src/api/tag';
import { validateUser } from 'src/api/userProfile';

// components
import WithErrorHandling, { RequestError } from 'src/components/hoc/withErrorHandling';
import CommunityHomePage from 'src/modules/community/communityHome/communityHomePage';

// utils
import { PageContext } from 'src/constants/types';

const Page = () => <CommunityHomePage />;

Page.getInitialProps = async ({ res, store: { dispatch, getState }, query: { communitySlug } }: PageContext) => {
  await dispatch(fetchCurrentCommunity(communitySlug));
  const state = getState();
  const { currentCommunity, communityErrorStatusCode, currentUser } = state.context;

  if (!currentCommunity) {
    const isHiddenPrivateCommunity = communityErrorStatusCode === 310;
    if (res) {
      // 314 code for community not found
      if (communityErrorStatusCode === 314) {
        // We first check if the slug is an ontology tag
        const tagName = slugify(communitySlug);
        const response = await validateTag(tagName);
        // Tag exists in ontology
        if (response.success) {
          res.writeHead(301, { Location: `/tag/${tagName}` }).end();
        }
        // Tag isn't in ontology but there is an associated new ontology tag
        if (!response.success && response.redirect) {
          res.writeHead(301, { Location: response.location }).end();
        }
        // We now check if the slug is a username
        const isUsername = await validateUser(communitySlug);
        if (isUsername) {
          res.writeHead(301, { Location: `/user/${communitySlug}` }).end();
        } else { // slug could be a deleted community (314), deleted tag (410 for no associated new tag) or neither of them
          throw new RequestError('Page does not exist', 404);
        }
      } else if (isHiddenPrivateCommunity) {
        if (!currentUser) throw new RequestError('This page is not available to visitors', 401);
        throw new RequestError('This is a private community', 404); // user is not a member
      }
    }
    return {};
  }

  const requests = [
    dispatch(fetchPostsWithFilter({ communitySlug, isLoggedIn: !!currentUser })),
    dispatch(fetchWidgets(communitySlug)),
  ];

  await Promise.all(requests);

  return {};
};

export default WithErrorHandling(Page);
