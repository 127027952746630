import { useEffect } from 'react';
import { useDispatch, useSelector } from 'src/store/store';
import { fetchPostsWithFilter } from 'src/modules/community/communityHome/actions';
import { fetchFeaturedPosts } from 'src/modules/shared/context/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';

export function useFetchPosts() {
  const dispatch = useDispatch();
  const communitySlug = useSelector(({ context }) => context.currentCommunity?.slug);
  const isCommunityMemberOrHUStaff = useSelector(({ context }) => context.currentUser?.isCommunityMemberOrHUStaff);
  const isLoggedIn = useSelector(({ context }) => !!context.currentUser);

  useEffect(() => {
    if (!communitySlug) return;
    // [PROD-1432] [BB-1787] We want to make sure that the default filter is set correctly after the user logs in or followed community
    dispatch(fetchPostsWithFilter({ communitySlug, isLoggedIn }));
  }, [isCommunityMemberOrHUStaff, dispatch, communitySlug, isLoggedIn]);
}

export function useFetchFeaturedPosts() {
  const dispatch = useDispatch();
  const lang = useSelector(({ context }) => context.lang);
  const communityId = useSelector(({ context }) => context.currentCommunity?.communityId);
  const isLoggedIn = useSelector(({ context }) => !!context.currentUser);

  useEffect(() => {
    if (communityId) {
      dispatch(fetchFeaturedPosts({
        communityId,
        userType: isLoggedIn ? 'member' : 'visitor',
        countryId: 'all',
        lang,
      }));
    }
  }, [communityId, dispatch, isLoggedIn, lang]);
}

export function useFootfall() {
  const communitySlug = useSelector(({ context }) => context.currentCommunity?.slug);

  useEffect(() => {
    if (communitySlug) {
      trackFootfallEvent(Event.ViewedCommunity, { communityPath: communitySlug });
    }
  }, [communitySlug]);
}
